<template>
  <div style="background: white">
    <Loading :loadingCounter="loadingCounter" />
    <v-sheet class="overflow-hidden">
      <NavBar tittle="label.lbl_locationMasterList" />
      <v-form ref="form">
        <v-container fluid>
          <!-- １行目スタート -->
          <v-row class="search-row">
            <!-- 倉庫名/倉庫コード -->
            <div class="search-autocomplete first-search-item">
              <v-autocomplete
                dense
                v-model="warehouseSelected"
                :items="warehouseList"
                :label="$t('label.lbl_warehouseNameCd')"
                class="txt-single"
                persistent-hint
                margin-bottom="0"
                :hint="setWarehouse()"
                :rules="[rules.warehouseRequired]"
                :error-messages="alertWarehouseMessage"
                @change="(event) => changeWarehouse(event)"
                pa-3
              >
                <template slot="item" slot-scope="data">
                  <span class="auto-txt-size"> {{ data.item.text }} </span>
                </template>
              </v-autocomplete>
            </div>
            <span class="require">*</span>
            <!-- 空白 -->
            <span class="item-spacer">&nbsp;</span>

            <!-- 状況 -->
            <div class="search-autocomplete first-search-item">
              <v-autocomplete
                dense
                v-model="situationSelected"
                :items="situationList"
                :label="$t('label.lbl_Situatuion')"
                class="txt-single"
                persistent-hint
                margin-bottom="0"
                :hint="setSituation()"
                pa-3
              >
                <template slot="item" slot-scope="data">
                  <span class="auto-txt-size"> {{ data.item.text }} </span>
                </template>
              </v-autocomplete>
            </div>
            <!-- 空白 -->
            <span class="item-spacer">&nbsp;</span>

            <!-- ロケーションNo -->
            <div class="search-textbox" style="float: left">
              <v-text-field
                dense
                outlined
                id="get-location"
                v-model="location"
                :label="$t('label.lbl_location')"
                persistent-hint
                clear-icon="mdi-close-circle"
                clearable
              >
              </v-text-field>
            </div>
            <!-- 空白 -->
            <span class="item-spacer">&nbsp;</span>

            <!-- ロケグループ名 -->
            <div class="search-autocomplete first-search-item">
              <v-autocomplete
                dense
                v-model="locationGSelected"
                :items="HeaderlocationGList"
                :label="$t('label.lbl_locationGroupName')"
                class="txt-single"
                persistent-hint
                margin-bottom="0"
                :hint="setLocationG()"
                pa-3
              >
                <template slot="item" slot-scope="data">
                  <span class="auto-txt-size"> {{ data.item.text }} </span>
                </template>
              </v-autocomplete>
            </div>
            <!-- 空白 -->
            <span class="item-spacer">&nbsp;</span>

            <!-- ロケ管理区分 -->
            <div class="search-autocomplete first-search-item">
              <v-autocomplete
                dense
                v-model="locationMngSelected"
                :items="locationMngList"
                :label="$t('label.lbl_locationMngSection')"
                class="txt-single"
                persistent-hint
                margin-bottom="0"
                :hint="setLocationMng()"
                pa-3
              >
                <template slot="item" slot-scope="data">
                  <span class="auto-txt-size"> {{ data.item.text }} </span>
                </template>
              </v-autocomplete>
            </div>
            <!-- 空白 -->
            <span class="item-spacer">&nbsp;</span>

            <!-- 坪貸区分 -->
            <div class="search-autocomplete first-search-item">
              <v-autocomplete
                dense
                v-model="pyeongCoinSelected"
                :items="pyeongCoinList"
                :label="$t('label.lbl_pyeongCoinSection')"
                class="txt-single"
                persistent-hint
                margin-bottom="0"
                :hint="setPyeongCoin()"
                pa-3
              >
                <template slot="item" slot-scope="data">
                  <span class="auto-txt-size"> {{ data.item.text }} </span>
                </template>
              </v-autocomplete>
            </div>
            <!-- 空白 -->
            <span class="item-spacer">&nbsp;</span>
          </v-row>

          <v-row>
            <div class="search-row-exeBtn">
              <!--ボタン領域-->
              <div class="btn-search-area" style="float: left">
                <!-- 検索ボタン -->
                <v-btn
                  color="primary"
                  id="get-search"
                  class="api-btn"
                  @click="getLocationList('get-search')"
                  >{{ $t("btn.btn_search") }}</v-btn
                >
              </div>
              <div style="float: right">
                <!-- 新規登録ボタン-->
                <v-btn
                  ref="btnInquiry"
                  color="primary"
                  class="other-btn"
                  @click="locationGsaveAdd('item')"
                >
                  {{ $t("btn.btn_iniRegist") }}
                </v-btn>
              </div>
            </div>
          </v-row>

          <v-row>
            <div class="display-search-result-row">
              <!--検索結果表示-->
              <div class="text-label" style="float: left">
                <span>総件数：{{ totalCount }}件</span>
              </div>

              <!--表示件数切替部分-->
              <div style="float: right">
                <!--表示件数-->
                <div class="text-label" style="clear: both; float: left">
                  {{ $t("label.lbl_itemsPerPage") }}
                </div>
                <!-- 空白 -->
                <span class="item-spacer" style="float: left">&nbsp;</span>

                <!--表示件数切替ボタン-->
                <div style="float: left">
                  <v-btn-toggle
                    v-model="itemsPerPageBtn"
                    color="blue darken-2"
                    class="toggle black--text"
                    right
                  >
                    <v-btn class="pasing-btn" value="10" @click="u_10_on()" small>
                      {{ 10 }}
                    </v-btn>
                    <v-btn class="pasing-btn" value="50" @click="u_10_clear(50)" small>
                      {{ 50 }}
                    </v-btn>
                    <v-btn class="pasing-btn" value="100" @click="u_10_clear(100)" small>
                      {{ 100 }}
                    </v-btn>
                  </v-btn-toggle>
                </div>
              </div>
            </div>
          </v-row>
        </v-container>
        <!-- データテーブル -->
        <v-container fluid>
          <v-data-table
            id="listData"
            fixed-header
            :headers="headerItems"
            :items="inputList"
            :page.sync="page"
            :server-items-length="itemsPerPage"
            disable-filtering
            :options.sync="sortOptions"
            :hide-default-footer="true"
            height="530px"
          >
            <!-- 詳細ボタン -->
            <template v-slot:[`item.detail`]="{ item }">
              <v-btn small @click="openUpdate(item)" text>
                <v-icon>mdi-pencil</v-icon>
              </v-btn>
            </template>
          </v-data-table>

          <div class="text-center pt-2">
            <v-pagination v-model="page" :length="pageCount" :total-visible="7"></v-pagination>
          </div>
        </v-container>
      </v-form>

      <v-navigation-drawer absolute temporary v-model="openMenu">
        <sideMenu></sideMenu>
      </v-navigation-drawer>

      <SimpleDialog
        :isShow.sync="infoDialog.isOpen"
        :title="infoDialog.title"
        :message="infoDialog.message"
        :redMessage="infoDialog.redMessage"
        :firstPageFlag="infoDialog.firstPageFlag"
        :outsideClickNotCloseFlg="infoDialog.outsideClickNotCloseFlg"
      />
    </v-sheet>
  </div>
</template>

<script>
import NavBar from "../../components/NavBar.vue";
import Loading from "@/components/loading";
import sideMenu from "@/components/Menu";
import SimpleDialog from "@/components/SimpleDialog";
import { commonUtil } from "../../assets/scripts/js/CommonUtil";
import { i18n } from "../../lang/lang.js";
import { appConfig } from "../../assets/scripts/js/AppConfig";
import { dateTimeHelper } from "../../assets/scripts/js/DateTimeHelper";
import { getParameter } from "../../assets/scripts/js/GetParameter";
import commonMixin from "@/mixins/CommonMixin";
import commonRules from "@/mixins/CommonRules";

export default {
  name: appConfig.SCREEN_ID.P_MST_005,
  components: {
    Loading,
    sideMenu,
    NavBar,
    SimpleDialog,
  },
  props: {
    isOpenDetailDialog: { type: Boolean, default: false },
    isMenu: { type: Boolean, default: false },
    entity: { type: Object, default: null },
  },
  mixins: [commonMixin, commonRules],
  data: () => ({
    // ローディング画面表示フラグ
    loadingCounter: 0,
    // 商品詳細情報登録画面ポップアップ
    openDatailsAdd: false,
    u_10: false,
    // 入力チェック結果フラグ
    isInputCheck: true,
    // 総件数
    totalCount: 0,
    // ページング
    itemsPerPageList: [10, 50, 100],
    page: 1,
    pageCount: 0,
    itemsPerPage: 10,
    itemsPerPageBtn: "10",
    // メニュー
    openMenu: null,
    // 倉庫名/倉庫コード
    alertWarehouseMessage: "",
    // 基準日
    referenceDate: "",
    referenceDateCal: "",
    dateMenu: false,
    // 遷移画面の名前を格納
    backpage: "",
    // 倉庫プルダウン
    warehouseSelected: "",
    warehouseList: [],
    // 状況 (一覧ボディ表示)
    situation: "",
    // 状況プルダウン
    situationSelected: "",
    situationSearch: "", // 状況検索用
    situationList: [],
    HeaderlocationGList: [],
    // ロケーションNo
    location: "",
    // ロケG名/ロケGプルダウン
    locationGSelected: "",
    locationGList: [],
    // ロケ管理区分 (一覧ボディ表示)
    locationMngSection: "",
    // 品質区分プルダウン
    locationMngSelected: "",
    locationMngList: [],
    // 坪貸区分 (一覧ボディ表示)
    pyeongCoinSection: "",
    // 坪貸区分プルダウン
    pyeongCoinSelected: "",
    pyeongCoinList: [],
    // テーブルフッター項目
    inputList: [],
    situatioSearchlist: [],
    // ソート
    sortItem: "",
    sortOptions: {},
    // 検索済みフラグ
    isSearched: false,
    // テーブルヘッダ項目
    headerItems: [
      // 倉庫名/倉庫コード
      {
        text: i18n.tc("label.lbl_warehouseNameCd"),
        value: "warehouseNameCd",
        width: "10%",
        align: "left",
        sortable: true,
      },
      // 状況
      {
        text: i18n.tc("label.lbl_Situatuion"),
        value: "situation",
        width: "5%",
        align: "left",
        sortable: true,
      },
      // 有効開始日時
      {
        text: i18n.tc("label.lbl_validStartDateAndTime"),
        value: "validStartDateAndTime",
        width: "7%",
        align: "left",
        sortable: true,
      },
      // 有効終了日時
      {
        text: i18n.tc("label.lbl_validEndDateAndTime"),
        value: "validEndDateAndTime",
        width: "7%",
        align: "left",
        sortable: true,
      },
      // ロケーションNo
      {
        text: i18n.tc("label.lbl_location"),
        value: "location",
        width: "7%",
        align: "left",
        sortable: true,
      },
      // ロケグループ
      {
        text: i18n.tc("label.lbl_locationGroupName"),
        value: "locationGroup",
        width: "8%",
        align: "left",
        sortable: true,
      },
      // ロケーション管理区分
      {
        text: i18n.tc("label.lbl_locationMngSection"),
        value: "locationMngSection",
        width: "7%",
        align: "left",
        sortable: false,
      },
      // 容量
      {
        text: `${i18n.tc("label.lbl_capacity")}` + `(㎥)`,
        value: "capacity",
        width: "5%",
        align: "right",
        sortable: true,
      },
      // 坪貸区分
      {
        text: i18n.tc("label.lbl_pyeongCoinSection"),
        value: "pyeongCoinSection",
        width: "5%",
        align: "left",
        sortable: false,
      },
      // ロケーション優先度
      {
        text: i18n.tc("label.lbl_locationGroupPriority"),
        value: "locationGroupPriority",
        width: "7%",
        align: "left",
        sortable: true,
      },
      // 詳細
      {
        text: i18n.tc("label.lbl_Detail"),
        value: "detail",
        align: "center",
        width: "2%",
        sortable: false,
      },
    ],
  }),
  methods: {
    // ページング
    u_10_check() {
      if (this.itemsPerPage < 11) {
        this.u_10 = true;
      }
    },
    u_10_on() {
      this.u_10 = true;
      this.itemsPerPage = 10;
      this.getLocationList("get-search");
    },
    u_10_clear(val) {
      this.u_10 = false;
      this.itemsPerPage = val;
      this.getLocationList("get-search");
    },

    created() {
      this.officeSelected = this.$route.params.officeSid;
      this.warehouseSelected = this.$route.params.warehouseSid;
      this.situationSelected = this.$route.params.situationSelected;
      this.locationGSelected = this.$route.params.locationGSelected;
      this.locationMngSelected = this.$route.params.locationMngSelected;
      this.pyeongCoinSelected = this.$route.params.pyeongCoinSelected;
      if (this.warehouseSelected) {
        this.getLocationList("get-search");
      } else {
        this.warehouseSelected = "";
      }
    },

    // 初期化
    init() {
      this.itemsPerPage = 10;
      this.itemsPerPageBtn = "10";
      this.backpage = this.$route.params.backpage;

      // 登録画面で戻るボタンを押下した場合
      if (this.backpage == "MstLocationAdd") {
        Object.assign(this, this.$route.params.searchFilter);
        this.getLocationList("get-search");
      } else {
        this.warehouseSelected = "";
        this.situationSelected = "";
        this.location = "";
        this.locationGSelected = "";
        this.locationMngSelected = "";
        this.pyeongCoinSelected = "";
        this.inputList = [];
      }
      this.referenceDate = dateTimeHelper.convertJST();
      this.referenceDateCal = dateTimeHelper.dateCalc(dateTimeHelper.convertJST());

      this.getWarehouse();
      this.getCodeMst();
      this.getHeaderLocationG();
    },

    // 新規登録ボタンからロケ登録画面遷移
    locationGsaveAdd() {
      // 入力チェック結果フラグ
      this.alertWarehouseMessage = "";
      let check = 0;
      // 倉庫名/倉庫コード存在チェック
      if (this.warehouseSelected == "" || this.warehouseSelected == null) {
        this.alertWarehouseMessage = i18n.tc("check.chk_inputWarehouse");
        check++;
      }
      if (check == 0) {
        let selectedWarehouseCdName = this.warehouseList.filter(
          (item) => item.value == this.warehouseSelected
        )[0].text;
        this.$router.push({
          name: appConfig.SCREEN_ID.P_MST_003,
          params: {
            officeSid: sessionStorage.getItem("sales_office_sid"), // 営業所SID（ログインユーザ情報）
            warehouseSid: this.warehouseSelected, //倉庫Sid
            warehouseCdName: selectedWarehouseCdName, //倉庫名
            locationGroup: "product0",
            // 検索ヘッダー情報
            searchFilter: this.searchFilter,
            backpage: "MstLocationListFromAdd",
          },
        });
      }
    },

    // ロケマスタ修正画面へ
    openUpdate(item) {
      // 選択した行のアイテム
      this.editedIndex = this.inputList.indexOf(item);

      // 修正画面へ渡す値
      this.$router.push({
        name: appConfig.SCREEN_ID.P_MST_003,
        params: {
          backpage: "MstLocationListFromDetail", // 画面名
          warehouseCdName:
            `${this.inputList[this.editedIndex].warehouseNameCd}` +
            `(${this.inputList[this.editedIndex].warehouseName})`,
          warehouseSid: this.inputList[this.editedIndex].warehouseSid, //倉庫Sid
          situation: this.inputList[this.editedIndex].situation, //状況
          validStartDateAndTime: dateTimeHelper.convertUTC(item.validFrom).substr(0, 10), //有効開始
          validEndDateAndTime: dateTimeHelper.convertUTC(item.validTo).substr(0, 10), //有効終了
          location: this.inputList[this.editedIndex].location, //ロケNo
          locationGroup: this.inputList[this.editedIndex].locationGroup, //ロケG
          locationMngSection: this.inputList[this.editedIndex].locationMngSection, //ロケ管理区分
          vertical: this.inputList[this.editedIndex].itemVertical, //たて
          width: this.inputList[this.editedIndex].itemBeside, //よこ
          height: this.inputList[this.editedIndex].itemHeight, //たかさ
          pyeongCoinSection: this.inputList[this.editedIndex].rentClassificationDiv, //坪貸区分
          locationGroupPriority: this.inputList[this.editedIndex].locationPriority, //ロケ優先度
          officeSid: this.inputList[this.editedIndex].officeSid,
          // 検索ヘッダー情報
          searchFilter: this.searchFilter,
          displayFlg: "2", //どこからの画面遷移か判別させるため
        },
      });
    },

    // 検索ボタン押下後、ロケーションマスタ一覧を取得
    getLocationList(val) {
      // 入力チェック結果フラグ
      this.alertWarehouseMessage = "";
      let check = 0;
      // 倉庫名/倉庫コード存在チェック
      if (this.warehouseSelected == "" || this.warehouseSelected == null) {
        this.alertWarehouseMessage = i18n.tc("check.chk_inputWarehouse");
        check++;
      }
      if (check == 0) {
        // ローディング画面表示ON
        this.loadingCounter = 1;
        const config = this.$httpClient.createGetApiRequestConfig();
        // 基準日対応
        config.params.reqComReferenceDate = dateTimeHelper.convertUTC(this.referenceDate);
        // 営業所SID（ログインユーザ情報）
        config.params.officeSid = sessionStorage.getItem("sales_office_sid");

        config.params.searchCategory = "1";

        config.params.reqComExecTimestamp = dateTimeHelper.convertUTC(this.referenceDate);
        // 倉庫プルダウン
        if (this.warehouseSelected) {
          config.params.warehouseSid = this.warehouseSelected;
        }
        // 状況プルダウン
        if (this.situationSelected) {
          if (this.situationSelected == "01") {
            config.params.useStatus = "1";
          } else if (this.situationSelected == "02") {
            config.params.useStatus = "2";
          } else if (this.situationSelected == "03") {
            config.params.useStatus = "0";
          }
        }

        // ロケテキスト入力
        if (this.location) {
          config.params.locationNo = this.location;
        }
        // ロケG名/ロケG
        if (this.locationGSelected) {
          config.params.locationGroup = this.locationGSelected;
        }
        // ロケ管理区分
        if (this.locationMngSelected) {
          config.params.qualityDiv = this.locationMngSelected;
        }
        // 坪貸区分
        if (this.pyeongCoinSelected) {
          config.params.rentClassificationDiv = this.pyeongCoinSelected;
        }

        // ページャー処理
        if (val == "get-search") {
          config.params.reqComPageIndex = "1";
          this.page = 1;
        } else {
          if (this.page >= 2 && this.inputList.length == 0) {
            config.params.reqComPageIndex = "1";
            this.page = 1;
          } else {
            config.params.reqComPageIndex = this.page;
          }
        }
        // ページング
        config.params.reqComPaginationFlg = "1";
        config.params.reqComPageLimit = this.itemsPerPage;
        // ソート
        if (this.sortItem != "") {
          config.params.reqComSortItem = this.sortItem;
        }

        // console.debug("getLocationList() Config", config);
        return new Promise((resolve, reject) => {
          this.$httpClient
            .secureGet(appConfig.API_URL.MST_LOCATION, config)
            .then((response) => {
              const jsonData = JSON.parse(JSON.stringify(response.data));
              // console.debug("getLocationList() Response", response);
              // 成功
              if (jsonData.resCom.resComCode == appConfig.RESCOMCODE_SUCCESS) {
                this.pageCount = jsonData.resCom.resComPagination.totalPage;
                this.totalCount = jsonData.resCom.resComPagination.totalRecord;

                const list = [];
                // ロケーションマスタリストを取得
                jsonData.resIdv.locations.forEach((row) => {
                  list.push({
                    useStatus: row.useStatus,
                    officeSid: row.officeSid,
                    warehouseNameCd: `${row.warehouseCd} \n` + `${row.warehouseName}`, //倉庫CD+倉庫名
                    warehouseName: row.warehouseName, //倉庫名
                    warehouseSid: row.warehouseSid, //倉庫Sid
                    situation: "", //状況
                    validStartDateAndTime: dateTimeHelper
                      .convertUTC2JST(row.validFrom)
                      .substr(0, 10), //有効開始
                    validEndDateAndTime: dateTimeHelper.convertUTC2JST(row.validTo).substr(0, 10), //有効終了
                    location: row.locationNo, //ロケNo
                    locationGroup: `${i18n.tc("label.lbl_locaGroup") + row.locationGroup}`, //ロケG
                    locationMngSection: row.qualityDivName, //ロケ管理区分
                    vertical: row.itemVertical, //たて
                    width: row.itemBeside, //よこ
                    height: row.itemHeight, //たかさ
                    capacity: commonUtil.formatToCurrency(Number(row.locationCapacityQuantity)), //容量
                    pyeongCoinSection: row.rentClassificationDivName, //坪貸区分
                    locationGroupPriority: row.locationPriorityName, //ロケ優先度
                  });
                });
                this.inputList = list;

                // let situatioSearchlist = [];
                for (let i = 0; i < this.inputList.length; i++) {
                  if (
                    this.inputList[i].validStartDateAndTime <= this.referenceDate &&
                    this.referenceDate <= this.inputList[i].validEndDateAndTime
                  ) {
                    // 使用中
                    this.situation = i18n.tc("label.lbl_use");
                  } else if (this.referenceDate < this.inputList[i].validStartDateAndTime) {
                    // 使用予定
                    this.situation = i18n.tc("label.lbl_planToUse");
                  } else if (this.inputList[i].validEndDateAndTime < this.referenceDate) {
                    // 使用済
                    this.situation = i18n.tc("label.lbl_used");
                  }
                  this.inputList[i].situation = this.situation;
                }

                // 検索値保持
                Object.assign(this.searchFilter, {
                  // 倉庫名/倉庫コード
                  warehouseSelected: this.warehouseSelected,
                  // 状況
                  situationSelected: this.situationSelected,
                  // ロケNo
                  location: this.location,
                  // ロケグループ名/ロケグループ
                  locationGSelected: this.locationGSelected,
                  // ロケ管理区分
                  locationMngSelected: this.locationMngSelected,
                  // 坪貸区分
                  pyeongCoinSelected: this.pyeongCoinSelected,
                });
              } else {
                // エラー時
                this.infoDialog.message = jsonData.resCom.resComMessage;
                this.infoDialog.title = appConfig.DIALOG.title;
                this.infoDialog.isOpen = true;
                this.infoDialog.firstPageFlag = true;
                resolve(response);
              }
            })
            .catch((ex) => {
              this.infoDialog.message = ex;
              this.infoDialog.title = appConfig.DIALOG.title;
              this.infoDialog.isOpen = true;
              this.infoDialog.firstPageFlag = true;
              reject(resolve);
            })
            .finally(() => {
              // ローディング画面表示OFF
              this.loadingCounter = 0;
            });
        });
      }
    },

    // 倉庫マスタ取得
    getWarehouse() {
      // ローディング画面表示ON
      this.loadingCounter = 1;

      const config = this.$httpClient.createGetApiRequestConfig();

      config.params.officeSid = sessionStorage.getItem("sales_office_sid");
      config.params.isMst = "0";
      config.params.searchCategory = "0";

      return new Promise((resolve, reject) => {
        this.$httpClient
          .secureGet(appConfig.API_URL.MST_WAREHOUSEBIZ_USESEARCH, config)
          .then((response) => {
            // console.debug("getWarehouse() Response", response);
            const jsonData = JSON.parse(JSON.stringify(response.data));

            if (jsonData.resCom.resComCode == appConfig.RESCOMCODE_SUCCESS) {
              const list = [{ text: "", value: "" }];
              jsonData.resIdv.warehouses.forEach((row) => {
                list.push({
                  text: `${row.warehouseCd}` + `(${row.warehouseLanguage[0].warehouseName})`,
                  value: row.warehouseLanguage[0].warehouseSid,
                  name: row.warehouseLanguage[0].warehouseName,
                });
              });

              this.warehouseList = list.filter((target) => {
                return !(target.value === null || target.value === "");
              });
              resolve(response);
            } else {
              this.infoDialog.message = jsonData.resCom.resComMessage;
              this.infoDialog.title = appConfig.DIALOG.title;
              this.infoDialog.isOpen = true;
              this.infoDialog.firstPageFlag = true;
              resolve(response);
            }
          })
          .catch((ex) => {
            this.infoDialog.message = ex;
            this.infoDialog.title = appConfig.DIALOG.title;
            this.infoDialog.isOpen = true;
            this.infoDialog.firstPageFlag = true;
            reject(resolve);
          })
          .finally(() => {
            // ローディング画面表示OFF
            this.loadingCounter = 0;
          });
      });
    },
    // ロケグループ(ヘッダー用)取得
    getHeaderLocationG() {
      // ローディング画面表示ON
      this.loadingCounter = 1;
      const config = this.$httpClient.createGetApiRequestConfig();
      // 検索区分
      config.params.searchCategoryDiv = "0";
      // 処理区分
      config.params.processDiv = "02";
      // ロケグループダイアログには倉庫SIDが必要
      if (this.warehouseSelected) {
        config.params.warehouseSid = this.warehouseSelected;
      }
      return new Promise((resolve, reject) => {
        this.$httpClient
          // 接続先のAPI_URLを入力
          .secureGet(appConfig.API_URL.MST_LOCATIONBIZ, config)
          .then((response) => {
            // console.debug("getHeaderLocationG() Response", response);
            const jsonData = JSON.parse(JSON.stringify(response.data));

            // 正常時
            if (jsonData.resCom.resComCode == appConfig.RESCOMCODE_SUCCESS) {
              const list = [];
              jsonData.resIdv.locations.forEach((row) => {
                list.push({
                  text: `${i18n.tc("label.lbl_locaGroup") + row.locationGroup}`,
                  value: row.locationGroup,
                });
              });
              this.HeaderlocationGList = list;

              resolve(response);
            } else {
              // エラー時
              this.infoDialog.message = jsonData.resCom.resComMessage;
              this.infoDialog.title = appConfig.DIALOG.title;
              this.infoDialog.isOpen = true;
              this.infoDialog.firstPageFlag = true;
              resolve(response);
            }
          })
          .catch((ex) => {
            this.infoDialog.message = ex;
            this.infoDialog.title = appConfig.DIALOG.title;
            this.infoDialog.isOpen = true;
            this.infoDialog.firstPageFlag = true;
            reject(resolve);
          })
          .finally(() => {
            // ローディング画面表示OFF
            this.loadingCounter = 0;
          });
      });
    },
    // コードマスタ取得：状況、ロケ管理区分、坪貸区分
    getCodeMst() {
      // ローディング画面表示ON
      this.loadingCounter = 1;
      // 坪貸区分
      const rentClassGet = getParameter.getCodeMst(appConfig.CODETYPE.RENTCLASSIFICATION_DIV);
      // ロケ管理区分
      const locMngGet = getParameter.getCodeMst(appConfig.CODETYPE.LOCATION_MNG_DIV);
      // ロケ優先度
      const locPrioGet = getParameter.getCodeMst(appConfig.CODETYPE.LOCATION_PRIO_DIV);
      // 状況
      const situationGet = getParameter.getCodeMst(appConfig.CODETYPE.SITUATION_DIV);
      Promise.all([rentClassGet, locMngGet, locPrioGet, situationGet])
        .then((result) => {
          // 画面の初期値を設定します。
          this.pyeongCoinList = result[0];
          this.locationMngList = result[1];
          this.locGPriorityList = result[2];
          this.situationList = result[3];
        })
        .catch((ex) => {
          this.infoDialog.message = ex;
          this.infoDialog.title = appConfig.DIALOG.title;
          this.infoDialog.isOpen = true;
          this.infoDialog.firstPageFlag = true;
        })
        .finally(() => {
          // ローディング画面表示OFF
          this.loadingCounter = 0;
        });
    },

    /**
     * 倉庫変更時、エラー初期化
     */
    changeWarehouse() {
      this.alertWarehouseMessage = "";
      this.locationGSelected = ""; // ロケグループ名
    },

    // ***プルダウンセット処理***
    // 倉庫セット
    setWarehouse() {
      for (var i = 0; i < this.warehouseList.length; i++) {
        if (this.warehouseList[i].value == this.warehouseSelected) {
          return this.warehouseList[i].name;
        }
      }
    },
    // 状況セット
    setSituation() {
      for (var i = 0; i < this.situationList.length; i++) {
        if (this.situationList[i].value == this.situationSelected) {
          return this.situationList[i].name;
        }
      }
    },
    // ロケGセット
    setLocationG() {
      for (var i = 0; i < this.locationGList.length; i++) {
        if (this.locationGList[i].value == this.locationGSelected) {
          return this.locationGList[i].name;
        }
      }
    },
    // ロケ管理区分セット
    setLocationMng() {
      for (var i = 0; i < this.locationMngList.length; i++) {
        if (this.locationMngList[i].value == this.locationMngSelected) {
          return this.locationMngList[i].name;
        }
      }
    },
    // 坪貸区分セット
    setPyeongCoin() {
      for (var i = 0; i < this.pyeongCoinList.length; i++) {
        if (this.pyeongCoinList[i].value == this.pyeongCoinSelected) {
          return this.pyeongCoinList[i].name;
        }
      }
    },

    /**
     * カレンダコンポーネントの日付はyyyy-MM-ddのためyyyy/MM/ddに変換する。
     */
    formatDate(date) {
      if (!date) return null;

      // 日付の正規表現（yyyy/MM/dd）
      const regex = /^[0-9]{4}\/(0[1-9]|1[0-2])\/(0[1-9]|[12][0-9]|3[01])$/;

      // 対象の文字列チェック
      if (regex.test(date)) {
        // 上記の日付の形で入ってきた場合、そのまま返却。（手入力した場合）
        return date;
      } else {
        // 上記以外の形で入ってきた場合は、スラッシュ編集で返却（データピッカーで日付を選んだ場合）
        const [year, month, day] = date.split("-");
        return `${year}/${month}/${day}`;
      }
    },
  },
  computed: {},
  watch: {
    // eslint-disable-next-line no-unused-vars
    isMenu: function (newValue, oldValue) {
      if (newValue) {
        this.init();
      }
    },
    warehouseSelected(newValue) {
      if (newValue || null) {
        this.warehouseSelected = newValue;
        this.getHeaderLocationG();
      } else {
        this.warehouseSelected = newValue;
        this.getHeaderLocationG();
      }
    },
    dialog(val) {
      val || this.close();
    },
    referenceDateCal(val) {
      this.referenceDate = this.formatDate(val);
    },
    page: function (newValue) {
      this.page = newValue;
      this.getLocationList("");
    },
    sortOptions: {
      handler(items) {
        // console.debug("watch sortByApi", items);
        if (items.sortBy.length < 1) {
          this.sortItem = "";
        } else {
          var sortBy = items.sortBy[0];
          var ascdesc = items.sortDesc[0] ? " desc" : " asc";
          this.sortItem = appConfig.P_MST_005_SORT[sortBy].replaceAll("{0}", ascdesc); // + ascdesc;
          this.getLocationList();
        }
      },
      deep: true,
    },
  },
  created() {
    this.init();
  },
};
</script>
<style>
@import "../../css/style.css";
</style>
<style lang="scss" scoped>
.txt-single ::v-deep {
  //padding: 25px 0 0 0;
  padding-right: 0;
  font-size: large;
}

.add-textbox-num {
  width: 16rem;
  padding: 20px 0 0 10px;
}

.overflow {
  overflow: hidden;
}

.toatlNum {
  margin-left: 10px;
}

.txt-center {
  margin: auto;
}

.link {
  text-decoration: none;
}
</style>
